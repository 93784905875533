import React from "react"
import { Container, Table, Header, Icon, Breadcrumb } from "semantic-ui-react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SpecsPage = ({ data }) => (
  <Layout>
    <SEO title="Languages" />
    <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
      <Breadcrumb>
        <Breadcrumb.Section link as={Link} to="/">
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider icon="right chevron" />
        <Breadcrumb.Section active>Languages</Breadcrumb.Section>
      </Breadcrumb>

      <Header as="h1" dividing>
        <Icon name="code" />
        <Header.Content>
          Programming Languages
          <Header.Subheader>
            Find Misconceptions by Language Feature
          </Header.Subheader>
        </Header.Content>
      </Header>
      <p>
        Most programming languages are authoritatively defined in publicly
        available specifications. If you are looking for misconceptions related
        to a specific feature of a specific language, you can do so here.
      </p>

      <Header as="h2" dividing>
        <Header.Content>
          Cross-Referenced Specifications
        </Header.Content>
      </Header>
      <p>
        For the following recent language specifications 
        we provide mappings from language specification section to the related misconceptions.
      </p>
      <Table celled compact collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Language</Table.HeaderCell>
            <Table.HeaderCell>Specification</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.allMdx.nodes.filter(node => node.frontmatter.crossReferenced).map((node, index) => (
            <Table.Row key={index}>
              <Table.Cell>{node.frontmatter.pl}</Table.Cell>
              <Table.Cell>
                <Link to={"/specs/" + node.parent.name}>
                  {node.frontmatter.name}&nbsp;
                  {node.frontmatter.version}
                </Link>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Header as="h2" dividing>
        <Header.Content>
          All Specifications
        </Header.Content>
      </Header>
      <p>
        We gathered a larger set of language specifications 
        than those we explicitly cross-referenced.
        The reason for this is that some misconceptions 
        are related to specific versions of a language,
        we indictate the first and/or last language version
        such misconceptions are related to,
        and those indicators may link to the corresponding
        specification versions shown below.
      </p>
      <Table celled compact collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Language</Table.HeaderCell>
            <Table.HeaderCell>Specification</Table.HeaderCell>
            <Table.HeaderCell>Cross-Referenced</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.allMdx.nodes.map((node, index) => (
            <Table.Row key={index}>
              <Table.Cell>{node.frontmatter.pl}</Table.Cell>
              <Table.Cell>
                <Link to={"/specs/" + node.parent.name}>
                  {node.frontmatter.name}&nbsp;
                  {node.frontmatter.version}
                </Link>
              </Table.Cell>
              <Table.Cell>{node.frontmatter.crossReferenced?"Yes":""}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Container>
  </Layout>
)

export default SpecsPage

export const query = graphql`
  {
    allMdx(
      filter: { fileAbsolutePath: { glob: "**/src/data/specs/*.md" } }
      sort: { fields: [frontmatter___pl, frontmatter___sortKey], order: ASC }
    ) {
      nodes {
        frontmatter {
          name
          pl
          version
          crossReferenced
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`
